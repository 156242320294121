import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnChanges {
    @Input() message!: string;
    content = this.message;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.content = this.message
    }
}
