import {Component, OnDestroy, OnInit} from '@angular/core';
import {SidenavService} from "../../services/sidenav.service";
import {HeaderTitleService} from "./header-title.service";
import {TranslocoService} from "@jsverse/transloco";
import {AuthService} from "../../../core/services/auth.service";
import {filter, takeUntil} from "rxjs/operators";
import {Event, NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    title = '';
    sidenavIsOpen: boolean = true;
    showSidenav: boolean = false;
    userInfo: any;

    private unsubscribe$ = new Subject<void>(); // Manages subscriptions to avoid memory leaks.

    constructor(
        private router: Router,
        private authService: AuthService,
        private sidenavService: SidenavService,
        private translocoService: TranslocoService,
        private headerTitleService: HeaderTitleService
    ) {
        // Subscribes to router events to update showSidenav property based on the current route.
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((event: NavigationEnd) => {
            const noSidenavRoutes = ['/access-error']; // List of routes where sidenav should not be displayed.
            this.showSidenav = !noSidenavRoutes.includes(event.urlAfterRedirects);
        });

        // Sets the active language to French.
        this.translocoService.setActiveLang('fr');
        this.title = this.translocoService.translate("headerTitle.home");

        // Subscribes to title changes from HeaderTitleService.
        this.headerTitleService.title.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((updatedTitle: string) => {
            this.title = updatedTitle;
        });

        // Subscribes to userInfo changes from AuthService.
        this.authService.userInfo.pipe(
            takeUntil(this.unsubscribe$) // Ensures that subscription is automatically unsubscribed when the component is destroyed.
        ).subscribe((userInfo: any) => {
            this.userInfo = userInfo;
        });
    }

    async ngOnInit() {
        // Retrieves user information upon component initialization.
        console.log("init")

    }

    ngOnDestroy() {
        this.unsubscribe$.next(); // Triggers the completion of all observables.
        this.unsubscribe$.complete(); // Ensures no further subscriptions are taken after the component is destroyed.
    }

    logout() {
        // Initiates the logout process.
        this.authService.logout();
    }

    toggleSidenav() {
        // Toggles the sidenav's open/close state.
        this.sidenavService.toggleMenu();
        this.sidenavIsOpen = !this.sidenavIsOpen;
    }
}
