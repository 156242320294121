<ng-container [ngSwitch]="type">
    <!-- arrow -->
    <ng-container *ngSwitchCase="'arrow-right-xs'">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" [attr.aria-label]="'icons.arrow' | transloco">
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="currentColor"/>
        </svg>
    </ng-container>

    <!-- breakfast -->
    <ng-container *ngSwitchCase="'breakfast-sm'">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.breakfast' | transloco">
            <title>{{ 'icons.breakfast' | transloco }}</title>
            <path d="M20.4951 10.94C20.6251 10.62 20.5951 10.71 20.6451 10.55C20.9451 9.34 20.3051 8.08 19.1451 7.62L17.1351 6.82C16.6751 6.64 16.1851 6.61 15.7251 6.7C15.6151 6.37 15.4351 6.07 15.2051 5.81C14.7251 5.29 14.0551 5 13.3551 5H10.6451C9.93512 5 9.26512 5.29 8.79512 5.81C8.55512 6.07 8.37512 6.37 8.26512 6.69C7.80512 6.6 7.31512 6.63 6.85512 6.81L4.84512 7.61C3.68512 8.07 3.04512 9.33 3.34512 10.54L3.49512 10.92C1.09512 15.55 0.995117 15.55 0.995117 16.38C0.995117 17.29 1.45512 18.12 2.23512 18.6C3.65512 19.48 4.72512 18.74 6.23512 17.99H17.7651C19.2851 18.75 19.6251 19 20.3951 19C21.3951 19 23.0051 18.23 23.0051 16.39C22.9951 15.54 22.8751 15.51 20.4951 10.94ZM20.1151 16.93L18.4351 16.09L19.5151 13.39L20.9251 16.1C21.2051 16.63 20.6351 17.19 20.1151 16.93ZM15.0851 15.99L15.7051 9.09C15.7351 8.76 16.0751 8.55 16.3851 8.67L18.3951 9.47C18.6151 9.56 18.7351 9.78 18.7051 10.01L16.3051 15.99H15.0851ZM7.67512 15.99L5.27512 10.01C5.24512 9.78 5.36512 9.56 5.58512 9.47L7.59512 8.67C7.90512 8.55 8.24512 8.75 8.27512 9.09L8.89512 15.99H7.67512ZM3.06512 16.1L4.47512 13.38L5.55512 16.09L3.87512 16.93C3.35512 17.19 2.78512 16.63 3.06512 16.1ZM10.1451 7.54C10.1151 7.23 10.3751 7 10.6451 7H13.3551C13.6251 7 13.8851 7.23 13.8551 7.54L13.0851 15.99H10.9151L10.1451 7.54Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'breakfast-md'">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.breakfast' | transloco">
            <title>{{ 'icons.breakfast' | transloco }}</title>
            <path d="M20.4951 10.94C20.6251 10.62 20.5951 10.71 20.6451 10.55C20.9451 9.34 20.3051 8.08 19.1451 7.62L17.1351 6.82C16.6751 6.64 16.1851 6.61 15.7251 6.7C15.6151 6.37 15.4351 6.07 15.2051 5.81C14.7251 5.29 14.0551 5 13.3551 5H10.6451C9.93512 5 9.26512 5.29 8.79512 5.81C8.55512 6.07 8.37512 6.37 8.26512 6.69C7.80512 6.6 7.31512 6.63 6.85512 6.81L4.84512 7.61C3.68512 8.07 3.04512 9.33 3.34512 10.54L3.49512 10.92C1.09512 15.55 0.995117 15.55 0.995117 16.38C0.995117 17.29 1.45512 18.12 2.23512 18.6C3.65512 19.48 4.72512 18.74 6.23512 17.99H17.7651C19.2851 18.75 19.6251 19 20.3951 19C21.3951 19 23.0051 18.23 23.0051 16.39C22.9951 15.54 22.8751 15.51 20.4951 10.94ZM20.1151 16.93L18.4351 16.09L19.5151 13.39L20.9251 16.1C21.2051 16.63 20.6351 17.19 20.1151 16.93ZM15.0851 15.99L15.7051 9.09C15.7351 8.76 16.0751 8.55 16.3851 8.67L18.3951 9.47C18.6151 9.56 18.7351 9.78 18.7051 10.01L16.3051 15.99H15.0851ZM7.67512 15.99L5.27512 10.01C5.24512 9.78 5.36512 9.56 5.58512 9.47L7.59512 8.67C7.90512 8.55 8.24512 8.75 8.27512 9.09L8.89512 15.99H7.67512ZM3.06512 16.1L4.47512 13.38L5.55512 16.09L3.87512 16.93C3.35512 17.19 2.78512 16.63 3.06512 16.1ZM10.1451 7.54C10.1151 7.23 10.3751 7 10.6451 7H13.3551C13.6251 7 13.8851 7.23 13.8551 7.54L13.0851 15.99H10.9151L10.1451 7.54Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>

    <!-- circle -->
    <ng-container *ngSwitchCase="'circle-xxs'">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill="currentColor"/>
        </svg>
    </ng-container>

    <!-- comment -->
    <ng-container *ngSwitchCase="'comment-sm'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.comment' | transloco">
            <title>{{ 'icons.comment' | transloco }}</title>
            <path d="M21.99 4C21.99 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22L21.99 4ZM20 4V17.17L18.83 16H4V4H20ZM6 12H18V14H6V12ZM6 9H18V11H6V9ZM6 6H18V8H6V6Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>

    <!-- info -->
    <ng-container *ngSwitchCase="'info-sm'">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" [attr.aria-label]="'icons.info' | transloco">
            <path d="M11.5215 7H13.4785V9H11.5215V7ZM11.5215 11H13.4785V17H11.5215V11ZM12.5 2C7.09846 2 2.7146 6.48 2.7146 12C2.7146 17.52 7.09846 22 12.5 22C17.9016 22 22.2854 17.52 22.2854 12C22.2854 6.48 17.9016 2 12.5 2ZM12.5 20C8.18464 20 4.67168 16.41 4.67168 12C4.67168 7.59 8.18464 4 12.5 4C16.8154 4 20.3283 7.59 20.3283 12C20.3283 16.41 16.8154 20 12.5 20Z"
                  fill="#051039"/>
        </svg>
    </ng-container>

    <!-- meal -->
    <ng-container *ngSwitchCase="'meal-sm'">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.meal' | transloco">
            <title>{{ 'icons.meal' | transloco }}</title>
            <path d="M2 19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V16H2V19ZM4 18H20V19H4V18Z" fill="black"
                  fill-opacity="0.58"/>
            <path d="M18.66 11.5C16.71 11.5 16.57 12.5 15.33 12.5C14.14 12.5 13.91 11.5 12 11.5C10.05 11.5 9.91 12.5 8.67 12.5C7.48 12.5 7.25 11.5 5.34 11.5C3.39 11.5 3.25 12.5 2.01 12.5V14.5C3.91 14.5 4.18 13.5 5.36 13.5C6.55 13.5 6.78 14.5 8.69 14.5C10.64 14.5 10.78 13.5 12.02 13.5C13.21 13.5 13.44 14.5 15.35 14.5C17.3 14.5 17.44 13.5 18.68 13.5C19.87 13.5 20.08 14.48 22 14.5L21.99 12.52C20.38 12.19 20.37 11.5 18.66 11.5Z"
                  fill="black" fill-opacity="0.58"/>
            <path d="M22 9C22.02 5 17.72 3 12 3C6.29 3 2 5 2 9V10H22V9ZM4.18 8C5.01 5.81 8.61 5 12 5C15.31 5 17.93 5.73 19.19 6.99C19.49 7.3 19.71 7.63 19.84 8H4.18Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'meal-md'">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.meal' | transloco">
            <title>{{ 'icons.meal' | transloco }}</title>
            <path d="M2 19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V16H2V19ZM4 18H20V19H4V18Z" fill="black"
                  fill-opacity="0.58"/>
            <path d="M18.66 11.5C16.71 11.5 16.57 12.5 15.33 12.5C14.14 12.5 13.91 11.5 12 11.5C10.05 11.5 9.91 12.5 8.67 12.5C7.48 12.5 7.25 11.5 5.34 11.5C3.39 11.5 3.25 12.5 2.01 12.5V14.5C3.91 14.5 4.18 13.5 5.36 13.5C6.55 13.5 6.78 14.5 8.69 14.5C10.64 14.5 10.78 13.5 12.02 13.5C13.21 13.5 13.44 14.5 15.35 14.5C17.3 14.5 17.44 13.5 18.68 13.5C19.87 13.5 20.08 14.48 22 14.5L21.99 12.52C20.38 12.19 20.37 11.5 18.66 11.5Z"
                  fill="black" fill-opacity="0.58"/>
            <path d="M22 9C22.02 5 17.72 3 12 3C6.29 3 2 5 2 9V10H22V9ZM4.18 8C5.01 5.81 8.61 5 12 5C15.31 5 17.93 5.73 19.19 6.99C19.49 7.3 19.71 7.63 19.84 8H4.18Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>

    <!-- meal tray -->
    <ng-container *ngSwitchCase="'mealtray-sm'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.meal' | transloco">
            <title>{{ 'icons.meal' | transloco }}</title>
            <path d="M7.79945 13.1285L10.6295 10.2985L3.60945 3.28853C2.04945 4.84853 2.04945 7.37853 3.60945 8.94853L7.79945 13.1285ZM14.5795 11.3185C16.1095 12.0285 18.2595 11.5285 19.8495 9.93853C21.7595 8.02853 22.1295 5.28853 20.6595 3.81853C19.1995 2.35853 16.4595 2.71853 14.5395 4.62853C12.9495 6.21853 12.4495 8.36853 13.1595 9.89853L3.39945 19.6585L4.80945 21.0685L11.6995 14.1985L18.5795 21.0785L19.9895 19.6685L13.1095 12.7885L14.5795 11.3185Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>

    <!-- on board -->
    <ng-container *ngSwitchCase="'on-board-sm'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.aria-label]="'icons.onBoard' | transloco">
            <title>{{ 'icons.onBoard' | transloco }}</title>
            <path d="M5.35 6.13987C4.45 5.49987 4.23 4.25987 4.86 3.34987C5.49 2.44987 6.74 2.22987 7.65 2.85987C8.55 3.49987 8.77 4.73987 8.14 5.64987C7.5 6.54987 6.26 6.76987 5.35 6.13987ZM16 19.4999H8.93C7.45 19.4999 6.19 18.4199 5.97 16.9599L4 7.49987H2L3.99 17.2599C4.37 19.6999 6.47 21.4999 8.94 21.4999H16V19.4999ZM16.23 15.4999H11.35L10.32 11.3999C11.9 12.2899 13.6 12.9399 15.47 12.6199V10.4899C13.84 10.7999 12.03 10.2199 10.78 9.23987L9.14 7.96987C8.91 7.78987 8.65 7.66987 8.38 7.58987C8.06 7.49987 7.72 7.46987 7.39 7.52987H7.37C6.14 7.74987 5.32 8.91987 5.53 10.1399L6.88 16.0599C7.16 17.4799 8.39 18.4999 9.83 18.4999H16.68L20.5 21.4999L22 19.9999L16.23 15.4999Z"
                  fill="black" fill-opacity="0.58"/>
        </svg>
    </ng-container>
</ng-container>