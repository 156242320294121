<div class="mat-elevation-z8 dense-1 table-container table-sticky">
    <table mat-table [dataSource]="dataSource" matSort>
        @for (column of columnsDefinition; track column.name) {
            <ng-container [matColumnDef]="column.name">
                @if (column.sortable) {
                    <th *matHeaderCellDef  mat-header-cell mat-sort-header
                        [id]="column.name"
                        scope="col"
                        class="title-table {{ column.headerStyle ?? ''}}"
                    >
                        {{ column.header | transloco }}
                    </th>
                } @else {
                    <th *matHeaderCellDef  mat-header-cell
                        [id]="column.name"
                        scope="col"
                        class="title-table {{ column.headerStyle ?? ''}}"
                    >
                        {{ column.header | transloco }}
                    </th>
                }
                <ng-container *matCellDef="let element">
                    <td [headers]="column.name" class="content-table" mat-cell [ngClass]="element | dynamicPipe:column.cellStylePipe">
                        @if (column.isCellCustomized === true) {
                            <ng-container
                                    *ngIf="customCellsTemplateRef"
                                    [ngTemplateOutlet]="customCellsTemplateRef"
                                    [ngTemplateOutletContext]="{$implicit:element, columnDefinition: column}"
                            >
                            </ng-container>
                        } @else {
                            @switch (column.cellType) {
                                @case ("innerHTML") {
                                    <div class="justify-space-between">
                                        <div [innerHTML]="element[column.name]"></div>
                                    </div>
                                }
                                @case ("date") {
                                    {{ element[column.name] | date:'HH:mm' }}
                                }
                                @default {
                                    {{ element[column.name] }}
                                }
                            }
                        }
                    </td>
                </ng-container>
            </ng-container>
        }

        <ng-container matColumnDef="actions">
            <th id="actions" scope="col" mat-header-cell *matHeaderCellDef class="title-table"></th>
            <td headers="actions" mat-cell *matCellDef="let element">
                <div class="justify-end">
                    <ng-container
                            *ngIf="actionsTemplateRef"
                            [ngTemplateOutlet]="actionsTemplateRef"
                            [ngTemplateOutletContext]="{$implicit:element}"
                    >
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="footer">
            <th mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length" class="table-footer">
                <mat-paginator [pageSizeOptions]="[15, 10, 20]"
                               showFirstLastButtons
                               [attr.aria-label]="'mealTray.tableOrders.selectPagePeriodicElements' | transloco">
                </mat-paginator>
                <div class="table-footer-content content-table" >
                    <ng-container *ngTemplateOutlet="footerTemplateRef"></ng-container>
                </div>
            </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="tr-mat-row" [ngClass]="(row | dynamicPipe:rowStylePipe) | async"></tr>
        <tr mat-footer-row *matFooterRowDef="['footer']; sticky: true"></tr>
    </table>
</div>
