import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {TranslocoService} from "@jsverse/transloco";

@Injectable({
    providedIn: 'root'
})
export class HeaderTitleService {

    constructor(private translocoService: TranslocoService) {}

    title = new BehaviorSubject(this.translocoService.translate("headerTitle.home"))

    setTitle(title: string) {
        const element = document.getElementById('main-title')
        //@ts-ignore
        element.textContent = title
    }

}
