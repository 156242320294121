import {
    Component, ContentChild, Input, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, SortDirection} from "@angular/material/sort";
import {BreakpointObserver } from "@angular/cdk/layout";
import {AbstractComponent} from "../abstract-component";
import {ResponsiveConfig} from "../../../models/responsive-config";
import {takeUntil} from "rxjs/operators";
import {responsiveBreakpoints} from "../../utils/const/responsive-breakpoints";

export interface ColumnDefinition {
    name: string,
    header: string,
    sortable?: boolean,
    cellType?: string,
    isCellCustomized?: boolean
    headerStyle?: string
    cellStyle?: string
    isEditable?: boolean
    cellStylePipe?: any
}

@Component({
    selector: 'logpn-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss'
})
export class TableComponent extends AbstractComponent implements OnInit {

    @Input() responsiveDisplayedColumns?: ResponsiveConfig<string[]>;

    private _displayedColumns!: string[];
    @Input() set displayedColumns(displayedColumns: string[]) {
        this._displayedColumns = displayedColumns;
        this.displayedColumns.push("actions");
    }
    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    @Input() columnsDefinition!: ColumnDefinition[];

    @Input() defaultSort?: string;
    @Input() defaultSortDirection: SortDirection = 'asc';

    private _dataSource!: MatTableDataSource<any>;
    @Input() set dataSource(dataSource: MatTableDataSource<any>) {
        this._dataSource = dataSource;
    }
    get dataSource(): MatTableDataSource<any> {
        return this._dataSource;
    }
    @Input() rowStylePipe?: any;

    // ContentChild for custom cells template
    @ContentChild('customCells', {static: false}) customCellsTemplateRef!: TemplateRef<any>;

    // ContentChild for action template
    @ContentChild('actions', {static: false}) actionsTemplateRef!: TemplateRef<any>;

    // ContentChild for footer template
    @ContentChild('footer', {static: false}) footerTemplateRef!: TemplateRef<any>;

    // ViewChild for pagination and sorting
    private paginator!: MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.dataSource.paginator = this.paginator;
    }

    private sort!: MatSort;
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.dataSource.sort = this.sort;
        if (this.defaultSort != null) {
            this.dataSource.sort.active = this.defaultSort;
        }
        this.dataSource.sort.direction = this.defaultSortDirection;
    }

    constructor(private breakpointObserver: BreakpointObserver) {
        super();
    }

    ngOnInit() {
        this.breakpointObserver.observe([
            responsiveBreakpoints.small,
            responsiveBreakpoints.medium,
            responsiveBreakpoints.large
        ]).pipe(takeUntil(this.destroy$)).subscribe(state => {
            if (this.responsiveDisplayedColumns) {
                if (state.breakpoints[responsiveBreakpoints.small]) {
                    this.displayedColumns = this.responsiveDisplayedColumns?.small;
                } else if (state.breakpoints[responsiveBreakpoints.medium]) {
                    this.displayedColumns = this.responsiveDisplayedColumns?.medium;
                } else if (state.breakpoints[responsiveBreakpoints.large]) {
                    this.displayedColumns = this.responsiveDisplayedColumns?.large;
                }
            }
        });
    }

    //reset paginator to first page
    public firstPage() {
        if(this.paginator){
            this.paginator.firstPage();
        }
    }
}
