import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {SidenavService} from "../../services/sidenav.service";


@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {

    selectedMenuItem: string | null = 'meal';

    selectMenuItem(menuItem: string) {
        this.selectedMenuItem = menuItem;
    }

    isSidenavOpen: boolean = true;
    private sidenavSubscription: Subscription;

    constructor(private sidenavService: SidenavService) {
        this.sidenavSubscription = this.sidenavService.isSidenavOpen$.subscribe(isOpen => {
            this.isSidenavOpen = isOpen;
        });
    }

    ngOnDestroy() {
        this.sidenavSubscription.unsubscribe();
    }

    getUrlDateToday(): string {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const currentDate = `${year}-${month}-${day}`;
        return `/meal-tray/order-by-date/${currentDate}`
    }
}
