import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrl: './generic-modal.component.scss'
})
export class GenericModalComponent {
    constructor(
        public dialogRef: MatDialogRef<GenericModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            content: string,
            leftButtonText: string,
            rightButtonText: string
        }
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }
}
