import { Pipe, PipeTransform, Injector, Type } from '@angular/core';

@Pipe({
    name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

    constructor(private injector: Injector) {}

    /**
     * Used to inject dynamic pipes
     * @param element element to trasnform
     * @param requiredPipe pipe
     * @param pipeArgs pipe arguments
     */
    transform(element: any, requiredPipe?: Type<any>, pipeArgs?: any): any {
        if (requiredPipe) {
            const injector = Injector.create({
                name: 'DynamicPipe',
                parent: this.injector,
                providers: [
                    {provide: requiredPipe}
                ]
            })
            const pipe = injector.get(requiredPipe)
            return pipe.transform(element, pipeArgs);
        }
        return null;
    }
}