import {Inject, Injectable} from '@angular/core';
import {OktaAuth, SignoutOptions, TokenResponse} from '@okta/okta-auth-js';
import {OKTA_AUTH} from "@okta/okta-angular";
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import {TokenManagerError} from "@okta/okta-auth-js/types/lib/oidc/types/TokenManager";

interface UserClaims {
  name?: string;
  given_name?: string;
  family_name?: string;
  employeeNumber?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  // print UserInfo
  private userInfoSubject : BehaviorSubject<UserClaims | null> = new BehaviorSubject<UserClaims | null>(null);
  public userInfo: Observable<UserClaims | null> = this.userInfoSubject.asObservable();

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private router: Router) {
    this.oktaAuth.tokenManager.on('renewed', (key, newToken) => {
      console.log(`Token with key ${key} was renewed`);
      this.getUserInfo();//Update user
    });
    this.oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      console.log(`Token with key ${key} has expired`);
      this.handleAuthenticationError(); // Token is expire
    });
    this.oktaAuth.tokenManager.on('error', (err : TokenManagerError) => {
      console.error('TokenManager error:', err);

      if (err.name === 'OAuthError') {
        this.oktaAuth.signInWithRedirect().catch((error) => {
          console.error(error.error)
          if (error.error === 'access_denied') {
            this.router.navigate(['/access-error']);
          } else {
            console.error('Error during sign-in redirect', error);
          }
        });
      }
      this.handleAuthenticationError(); // TokenManager error
    });
  }

  async isAuthenticated(): Promise<boolean> {
    return await this.oktaAuth.isAuthenticated();
  }

  async getAccessToken(): Promise<string | undefined> {
    const tokens = await this.oktaAuth.tokenManager.getTokens();

    if(tokens?.accessToken && !this.userInfoSubject.value){
      await this.getUserInfo();
    }
    return tokens.accessToken ? tokens.accessToken.accessToken : undefined;
  }

  async getUserInfo() {
    const tokens = await this.oktaAuth.tokenManager.getTokens();
    if (tokens.idToken) {
      const decodedToken : UserClaims = jwtDecode(tokens.idToken.idToken);
      console.debug("Update user info")
      this.userInfoSubject.next(decodedToken);
      //return decodedToken;
    }
  }

  async login(): Promise<void>  {
    console.log("login")

    await this.oktaAuth.signInWithRedirect().catch((error) => {
      console.error(error.error)
      if (error.error === 'access_denied') {
        this.router.navigate(['/access-error']);
      } else {
        console.error('Error during sign-in redirect', error);
      }
    });
  }

  async logout(): Promise<void> {
    console.log("logout")
    try {
      const signOutOptions = {
        postLogoutRedirectUri: window.location.origin,
        idToken: this.oktaAuth.tokenManager.getTokensSync().idToken?.idToken
      };
      await this.oktaAuth.signOut(<SignoutOptions>{signOutOptions});
      this.oktaAuth.tokenManager.clear();
      this.userInfoSubject.next(null);//Remove user data
      //this.router.navigate(['/']);
    } catch (error) {
      console.error('Logout error:', error);
    }
  }

  async handleAuthentication(): Promise<void> {
    try {
      const tokenContainer: TokenResponse = await this.oktaAuth.token.parseFromUrl();
      if (tokenContainer?.tokens) {
        this.oktaAuth.tokenManager.setTokens(tokenContainer.tokens);
        await this.getUserInfo(); // Update user after login
      }else {
        throw new Error('Token container is empty');
      }
    } catch (error:any) {
      console.error('Authentication handling error:', error);
      if (error.error === 'access_denied') {
        this.router.navigate(['/access-error']);
      } else {
        this.handleAuthenticationError();
      }
    }
  }
  handleAuthenticationError(): void {
    console.log("handleAuthenticationError")
  }
}
